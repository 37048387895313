function headerOnScroll() {
	let scroll = $(window).scrollTop();
	let headerTopContentHeight = $(
		`.o-header__top-content-wrapper`,
	).outerHeight();
	let windowWidth = $(window).outerWidth();

	if (scroll >= headerTopContentHeight && windowWidth >= 1024) {
		$(`.o-header`).addClass(`o-header--onscroll`);
	} else {
		$(`.o-header`).removeClass(`o-header--onscroll`);
	}
}

function header() {
	$(document).ready(headerOnScroll);
	$(window).scroll(headerOnScroll);
}

/*
 * Handling Accessibility of Header Menu/Submenu. Making sure Menu is compatible with assistive technology
 */
var menuItems = document.querySelectorAll(
	'.o-header__nav li.menu-item-has-children',
);

// Loop through each menu item
Array.prototype.forEach.call(menuItems, function(el, i) {
	var activatingA = el.querySelector('a');
	const button = el.querySelector('button'); // Get the button (if it exists)

	// Function to open the submenu
	function openSubMenu() {
		el.classList.add('has-submenu-open'); // Add class to show submenu
		activatingA.setAttribute('aria-expanded', 'true'); // Set aria-expanded attribute to true
		if (button) {
			button.setAttribute('aria-expanded', 'true'); // Set aria-expanded attribute of button to true
		}
		// Add event listeners for click outside and Escape key
		document.addEventListener('click', closeSubMenuOnClickOutside);
		document.addEventListener('keydown', closeSubMenuOnEscape);
	}

	// Function to close the submenu
	function closeSubMenu() {
		el.classList.remove('has-submenu-open'); // Remove class to hide submenu
		activatingA.setAttribute('aria-expanded', 'false'); // Set aria-expanded attribute to false
		if (button) {
			button.setAttribute('aria-expanded', 'false'); // Set aria-expanded attribute of button to false
		}
		// Remove event listeners for click outside and Escape key
		document.removeEventListener('click', closeSubMenuOnClickOutside);
		document.removeEventListener('keydown', closeSubMenuOnEscape);
	}

	// Function to close the submenu when clicked outside the menu item
	function closeSubMenuOnClickOutside(event) {
		if (!el.contains(event.target)) {
			closeSubMenu();
		}
	}

	// Function to close the submenu when the Escape key is pressed
	function closeSubMenuOnEscape(event) {
		if (event.key === 'Escape') {
			closeSubMenu();
		}
	}

	// Event listener for mouseenter to open submenu
	el.addEventListener('mouseenter', function() {
		if (el.classList.contains('menu-item-has-children')) {
			openSubMenu();
		}
	});

	// Event listener for mouseleave to close submenu
	el.addEventListener('mouseleave', function() {
		if (el.classList.contains('menu-item-has-children')) {
			closeSubMenu();
		}
	});

	// Event listener for focusin to close submenu
	el.addEventListener('focusin', function() {
		if (el.classList.contains('menu-item-has-children')) {
			openSubMenu();
		}
	});

	// Event listener for focusout to close submenu
	el.addEventListener('focusout', function(event) {
		// Delay the focusout to allow time to check if the new focused element is within the same menu item
		setTimeout(() => {
			if (!el.contains(document.activeElement)) {
				closeSubMenu();
			}
		}, 0);
	});
});

export default header;
