/**
 * Initializes input fields with character limits and manages button states based on input completeness.
 * Applies character limits, sets up input, radio, and focus event handlers, and checks the initial button state.
 */
function gformsCharacterLimit() {
	applyCharacterLimit('js-pattern-lrn', 13);
	applyCharacterLimit('js-pattern-ssin', 10);
	setupRadioListeners();
}

/**
 * Configures event listeners for input elements to enforce character limits and manage UI interactions,
 * such as cursor behavior and button enable/disable state.
 * @param {string} className - The class name of the input elements to be managed.
 * @param {number} maxLength - The maximum number of digits the input should accept.
 */
function applyCharacterLimit(className, maxLength) {
	const elements = document.querySelectorAll(`.${className}`);
	elements.forEach(element => {
		if (element.tagName.toLowerCase() === 'input') {
			element.maxLength = maxLength;

			element.addEventListener('input', function(e) {
				toggleButtonState(e.target, maxLength);
			});

			element.addEventListener('paste', function(e) {
				e.preventDefault(); // Prevent the default paste behavior

				const clipboardData = e.clipboardData || window.clipboardData;
				let pastedData = clipboardData.getData('Text');

				// Keep only the first maxLength characters and digits
				pastedData = pastedData.replace(/\D/g, '').slice(0, maxLength);

				// Set the value
				element.value = pastedData;

				// Move the cursor to the beginning of the input field
				element.setSelectionRange(0, 0);

				toggleButtonState(element, maxLength);
			});

			element.addEventListener('focus', function() {
				const valueLength = element.value.length;
				element.setSelectionRange(valueLength, valueLength); // Move cursor to the end of current input
			});

			// Initial state setup for this element
			toggleButtonState(element, maxLength);
		}
	});

	// Check if the jQuery Mask plugin is available
	if (jQuery.fn.mask) {
		// Apply mask using jQuery
		const maskPattern = '9'.repeat(maxLength); // Create a string of '9' repeated maxLength times
		jQuery(`.${className}`)
			.mask(maskPattern, { autoclear: false })
			.on('keyup', function(e) {
				toggleButtonState(this, maxLength);
			});
	} else {
		console.warn(
			'jQuery Mask plugin is not available. Mask functionality will be skipped.',
		);
	}
}

/**
 * Sets up event listeners for radio buttons within the '.js-required-radio' elements.
 */
function setupRadioListeners() {
	const radios = document.querySelectorAll('.js-required-radio input[type="radio"]');
	radios.forEach(radio => {
		radio.addEventListener('change', function(e) {
			// The maxLength parameter is not needed here, so passing 0.
			toggleButtonState(e.target, 0);
		});
	});
}

/**
 * Toggles the disabled state of the 'Next' button based on whether the inputs and, if present, the required radio are complete.
 * It checks that:
 * - If a '.js-required-radio' element exists, a radio input within it is checked.
 * - If an input with class '.js-pattern-lrn' exists, it contains exactly 13 digits.
 * - If an input with class '.js-pattern-ssin' exists, it contains exactly 10 digits.
 * @param {HTMLElement} inputElement - The element that triggered the event.
 * @param {number} maxLength - The maximum length for the triggering input (not used for the overall check).
 */
function toggleButtonState(inputElement, maxLength) {
	// Find the closest form page container.
	let formPageElement = inputElement.closest('.gform_page');
	if (!formPageElement) {
		return;
	}

	let isComplete = true;

	// If a required radio group exists, ensure at least one radio is checked.
	let requiredRadio = formPageElement.querySelector('.js-required-radio');
	if (requiredRadio) {
		let checkedRadio = requiredRadio.querySelector('input[type="radio"]:checked');
		if (!checkedRadio) {
			isComplete = false;
		}
	}

	// Check the '.js-pattern-lrn' input, if it exists.
	let lrnInput = formPageElement.querySelector('.js-pattern-lrn');
	if (lrnInput) {
		let cleanLrn = lrnInput.value.replace(/\D+/g, '');
		if (cleanLrn.length !== 13) {
			isComplete = false;
		}
	}

	// Check the '.js-pattern-ssin' input, if it exists.
	let ssinInput = formPageElement.querySelector('.js-pattern-ssin');
	if (ssinInput) {
		let cleanSsin = ssinInput.value.replace(/\D+/g, '');
		if (cleanSsin.length !== 10) {
			isComplete = false;
		}
	}

	// Find the 'Next' button.
	let nextButton = formPageElement.querySelector('.gform_next_button');
	if (!nextButton) {
		return;
	}

	// Enable or disable the Next button based on overall completeness.
	if (isComplete) {
		nextButton.removeAttribute('disabled');
	} else {
		nextButton.setAttribute('disabled', 'disabled');
	}
}

// Initialize the functionality once the DOM is fully loaded.
document.addEventListener('DOMContentLoaded', function() {
	gformsCharacterLimit();
});

// Reinitialize the functionality when a new form page is loaded via AJAX.
jQuery(document).on('gform_page_loaded', function(
	event,
	form_id,
	current_page,
) {
	gformsCharacterLimit();
});

// Reapply the functionality after a form is rendered via AJAX.
jQuery(document).on('gform_post_render', function(event, form_id) {
	gformsCharacterLimit();
});

export default gformsCharacterLimit;
